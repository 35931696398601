<template>
  <div class="container dispatchTasks-warp">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img class="img-title" :src="require('@/assets/image/pfrw.png')" alt="" />
      <div class="head-bottom-warp">
        <div class="head-item align-left blue">
          <div class="bg-blur"></div>
          <img :src="require('@/assets/image/xq1.png')" class="img-icon" /><span
            >未处理任务详情</span
          >
        </div>
      </div>
    </div>
    <div class="panel-warp">
      <ul class="panel-warp-ul">
        <li>
          <div class="panel-item-left bra3 panel-item-block">任务名称</div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{ forms.name }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            任务地点
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{ forms.areaAllName }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            任务说明
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            {{ forms.info }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">开始时间</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.formatDate(forms.time) }}
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">结束时间</div>
          <div class="panel-item-right bra3 plr panel-item-block">
            {{ $dt.formatDate(forms.deadline) }}
          </div>
        </li>
         <li>
          <div class="panel-item-left bra3 panel-item-block">事件照片</div>
          <div
            class="panel-item-right bra3 panel-item-block fill panel-img-warp"
          >
            <img
              v-for="(item, index) in forms.photos"
              :key="index"
              :src="item"
              class="panel-img-rec"
              alt=""
              @click="handlePreImg(forms.photos, index)"
            />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block">事件视频</div>
          <div
            class="panel-item-right bra3 panel-item-block fill panel-img-warp"
          >
            <!-- <video
              v-for="(item, index) in forms.hdlVideos"
              :key="index"
              :src="item"
              class="panel-img-rec"
              @click="handlePreVideo(item)"
            ></video> -->
            <div class="panel-img-rec video-bg-warp" v-for="(item, index) in forms.videos"      @click="handlePreVideo(item)" :key="index">
              <img  src="../../assets/image/shipin-copy.png" />
            </div>
          </div>
        </li>
      </ul>
      <div class="warp-btn">
        <van-button
          type="info"
          color="rgb(38, 120, 255)"
          class="m-r8"
          @click="routerTo('transfer')"
          >任务转交</van-button
        >
        <van-button
          type="info"
          color="rgb(38, 120, 255)"
          @click="routerTo('dispise')"
          >立即处理</van-button
        >
      </div>
      <van-image-preview
        v-model="show"
        closeable
        :images="preImages"
        :start-position="startIndex"
      >
      </van-image-preview>
      <van-dialog
        style="width:100%;border-radius:0;height:200px"
        theme="default"
        v-model="showvideoplay"
        :show-cancel-button="false"
        :show-confirm-button="false"
        closeOnClickOverlay
      >
        <video
          controls
          preload="auto"
          style="width:100%;height:200px;object-fit: contain;"
          :src="videourl"
          playsinline 
          x5-video-player-type="h5" 
          x5-video-player-fullscreen="true"
          x5-video-orientation="portraint" 
          x-webkit-airplay="true"
          v-if="videourl"
        ></video>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixin/detailMixin";
export default {
  mixins: [mixin],
  data() {
    return {
      interfaceUrl: "/api/event/get",
    };
  },
  mounted() {},
  methods: {
    routerTo(type) {
      if (type === "transfer") {
        this.$router.push({
          path: "dispatchTasksTaskTransfer",
          query: {
            id: this.$route.query.id,
          },
        });
      } else {
        this.$router.push({
          path: "dispatchTasksTaskProcessing",
          query: {
            id: this.$route.query.id,
          },
        });
      }
    },
  },
};
</script>

<style lang="less">
.dispatchTasks-warp {
  letter-spacing: 0px;
  position: relative;
  overflow: auto;
}
.warp-btn {
  display: flex;
  padding: 20px;
  justify-content: center;
  .van-button {
    width: 112px;
  }
}
</style>
